import { Checkbox, Select, TextInput } from '@liveeo/component-library';
import { useController, useFormContext } from 'react-hook-form';
import classes from './Inputs.module.css';
import { CheckboxProps, SelectProps, TextInputProps } from '@mantine/core';

type InputProps = TextInputProps &
  CheckboxProps &
  SelectProps & {
    label: string;
    name: string;
    type?: 'text' | 'checkbox' | 'select';
    theme?: 'dark' | 'light';
  };

const BaseInput = ({ label, name, type = 'text', ...props }: InputProps) => {
  const form = useFormContext();
  const { field, fieldState } = useController({
    name,
    control: form?.control,
  });

  const Component = {
    text: TextInput,
    checkbox: Checkbox,
    select: Select,
  }[type];

  return (
    <Component
      label={label}
      placeholder={label}
      error={fieldState.error?.message}
      {...props}
      {...field}
    />
  );
};

export const Input = ({ theme = 'dark', ...props }: InputProps) => (
  <BaseInput
    type="text"
    className={classes.flexGrow}
    classNames={{
      input:
        theme === 'dark' ? classes.darkBackground : classes.lightBackground,
      label: theme === 'light' ? classes.colorDark7 : '',
    }}
    {...props}
  />
);

export const CheckboxInput = (props: InputProps) => {
  const { watch } = useFormContext();
  return <BaseInput type="checkbox" {...props} checked={watch(props.name)} />;
};

export const SelectInput = ({ theme = 'dark', ...props }: InputProps) => (
  <BaseInput
    type="select"
    classNames={{
      input:
        theme === 'dark' ? classes.darkBackground : classes.lightBackground,
      label: theme === 'light' ? classes.colorDark7 : '',
      options: theme === 'dark' ? classes.darkBackground : '',
      option:
        theme === 'dark' ? classes.selectOptionDark : classes.selectOptionLight,
      dropdown: classes.trasparentBackground,
    }}
    data={props.data}
    {...props}
  />
);
