import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useNotification } from './useNotification';
import { useFetchApi } from './useFetchApi';
import { HsCode, Transaction } from '../shared/types';
import { useUser } from './useUser';
export const useTransactions = () => {
  const fetch = useFetchApi();
  const { apiError, successfullyCreated, successfullyDeleted } =
    useNotification();

  const {
    data: transactions,
    isLoading: isLoadingTransactions,
    isFetching: isFetchingTransactions,
  } = useQuery({
    queryKey: ['transactions'],
    queryFn: () =>
      fetch(
        'transactions?includes=components.supplierBusiness,components.ddsReferences'
      ) as Promise<Transaction[]>,
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  const {
    data: hsCodes,
    isLoading: isLoadingHsCodes,
    isFetching: isFetchingHsCodes,
  } = useQuery({
    queryKey: ['hs-codes'],
    queryFn: () => fetch('components/hs-codes') as Promise<HsCode[]>,
    onError: apiError,
    refetchOnWindowFocus: false,
  });

  const queryClient = useQueryClient();
  const { transactionId: tId } = useParams();
  const { data: business } = useUser();
  const createTransaction = useMutation({
    mutationKey: ['transactions', business?.businessId],
    mutationFn: (payload: Partial<Transaction>) => {
      const transactionId = tId !== 'new' ? tId : '';
      const url = transactionId
        ? `transactions/${transactionId}`
        : `businesses/${business?.businessId}/transactions/${transactionId}`;
      return fetch(url, {
        method: transactionId ? 'PATCH' : 'POST',
        body: JSON.stringify({
          ...payload,
          components: payload.components?.map((component) => ({
            ...component,
            supplierBusinessId: component.supplierBusiness.id,
            supplierBusiness: undefined,
          })),
          ...(transactionId && {
            components: undefined,
            activityType: undefined,
            selectedHsCodes: undefined,
            status: undefined,
          }),
        }),
      });
    },
    onSuccess: () => {
      successfullyCreated('transaction');
      queryClient.invalidateQueries(['transactions']);
    },
    onError: apiError,
  });

  const deleteTransaction = useMutation({
    mutationFn: (id: string) =>
      fetch(`transactions/${id}`, {
        method: 'DELETE',
      }),
    onSuccess: (_, id) => {
      successfullyDeleted(`transaction with ID: ${id}`);
      queryClient.invalidateQueries(['transactions']);
    },
    onError: apiError,
  });

  const useTransactionById = (transactionId: string | undefined) =>
    useQuery({
      enabled: !!transactionId && transactionId !== 'new',
      queryKey: ['transaction', transactionId],
      queryFn: () =>
        transactionId !== 'new'
          ? (fetch(`transactions/${transactionId}`) as Promise<Transaction>)
          : Promise.resolve(undefined),
      onError: transactionId !== 'new' ? apiError : undefined,
      refetchOnWindowFocus: false,
    });

  return {
    transactions,
    hsCodes,
    isLoading: isLoadingTransactions || isLoadingHsCodes,
    isFetching: isFetchingTransactions || isFetchingHsCodes,
    createTransaction,
    deleteTransaction,
    useTransactionById,
  };
};
