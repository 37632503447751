import { useState } from 'react';
import {
  Badge,
  Beta,
  Button,
  Checkbox,
  Flex,
  Stack,
  TextInput,
} from '@liveeo/component-library';
import {
  Controller,
  Control,
  FieldValues,
  Path,
  UseFormSetValue,
} from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Component, HsCode } from '../../../shared/types/Transaction';
import Fuse from 'fuse.js';
import { useQueryParam } from 'use-query-params';

export const HsCodeSelect = <T extends FieldValues>({
  control,
  setValue,
  setIsModalOpen,
  name,
  hsCodes,
}: {
  control: Control<T>;
  setValue: UseFormSetValue<T>;
  setIsModalOpen: (value: boolean) => void;
  name: Path<T>;
  hsCodes: HsCode[];
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<string[]>([]);
  const [search, setSearchQuery] = useQueryParam<string>('search');

  // fusejs config documentation https://www.fusejs.io/api/options.html
  const fuse = new Fuse<HsCode>(hsCodes, {
    keys: ['commodity', 'hsCode', 'description'],
    useExtendedSearch: true,
    threshold: 0.1,
  });

  const searchedHsCodes = search
    ? fuse.search(search).map((res) => res.item)
    : hsCodes;

  return (
    <Stack>
      <TextInput
        label={t('transactions.form.searchHsCodes')}
        placeholder={t('transactions.form.searchHsCodePlaceholder') ?? ''}
        leftSection={<Beta.Icon icon="search" />}
        onChange={(e) => setSearchQuery(e.target.value)}
        defaultValue={search}
        mb="md"
      />
      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <Checkbox.Group {...field}>
            <Stack>
              {searchedHsCodes
                ?.filter((item) => item.commodity !== null)
                ?.map(
                  (item) =>
                    !item.parentHsCode && (
                      <Stack key={item.hsCode}>
                        <Flex
                          align="center"
                          gap="sm"
                          onClick={() =>
                            isOpen.includes(item.hsCode)
                              ? setIsOpen(
                                  isOpen.filter((i) => i !== item.hsCode)
                                )
                              : setIsOpen([...isOpen, item.hsCode])
                          }
                        >
                          <Beta.Icon
                            icon={
                              isOpen.includes(item.hsCode)
                                ? 'chevron-up'
                                : 'chevron-down'
                            }
                          />
                          <Badge color="dark.6" size="md" radius="xs">
                            {item.hsCode}
                          </Badge>
                          <Beta.Text variant="b3" style={{ flex: 1 }}>
                            {item.description}
                          </Beta.Text>
                        </Flex>
                        {isOpen.includes(item.hsCode) && (
                          <Stack>
                            {hsCodes
                              .filter(
                                (child) => child.parentHsCode === item.hsCode
                              )
                              .map((child) => (
                                <Stack key={child.hsCode}>
                                  <Flex align="center" gap="sm">
                                    <Beta.Icon
                                      icon={
                                        isOpen.includes(child.commodity)
                                          ? 'chevron-up'
                                          : 'chevron-down'
                                      }
                                      onClick={() =>
                                        isOpen.includes(child.hsCode)
                                          ? setIsOpen(
                                              isOpen.filter(
                                                (i) => i !== child.hsCode
                                              )
                                            )
                                          : setIsOpen([...isOpen, child.hsCode])
                                      }
                                    />

                                    <Checkbox
                                      value={child.hsCode}
                                      mx={7}
                                      label={
                                        <>
                                          <Badge
                                            color="dark.6"
                                            size="md"
                                            ml={10}
                                            radius="xs"
                                          >
                                            {child.hsCode}
                                          </Badge>
                                          <Beta.Text variant="b3" span ml={10}>
                                            {child.description}
                                          </Beta.Text>
                                        </>
                                      }
                                    />
                                  </Flex>

                                  {isOpen.includes(child.hsCode) && (
                                    <Stack>
                                      {hsCodes
                                        .filter(
                                          (grandchild) =>
                                            grandchild.parentHsCode ===
                                            child.hsCode
                                        )
                                        .map((grandchild) => (
                                          <Flex
                                            key={grandchild.hsCode}
                                            align="center"
                                            gap="sm"
                                          >
                                            <Badge
                                              color="dark.6"
                                              size="md"
                                              radius="xs"
                                            >
                                              {grandchild.hsCode}
                                            </Badge>
                                            <Checkbox
                                              value={grandchild.hsCode}
                                              style={{
                                                flex: 1,
                                                whiteSpace: 'normal',
                                              }}
                                              label={grandchild.description}
                                            />
                                          </Flex>
                                        ))}
                                    </Stack>
                                  )}
                                </Stack>
                              ))}
                          </Stack>
                        )}
                      </Stack>
                    )
                )}
            </Stack>
            <Flex justify="end" gap="sm" mt="lg">
              <Button
                variant="outline"
                size="sm"
                onClick={() => setIsModalOpen(false)}
              >
                {t('common.cancel')}
              </Button>
              <Button
                variant="outline"
                size="sm"
                onClick={() => {
                  setValue('selectedHsCodes' as Path<T>, field.value);
                  setValue(
                    'components' as Path<T>,
                    field.value.map((hsCode: Component) => {
                      const hsCodeObj = hsCodes?.filter(
                        (elem) => hsCode.toString() === elem.hsCode
                      );
                      return {
                        description: hsCodeObj[0]?.description,
                        hsn: hsCode,
                        namePairs: [
                          {
                            commonName: '',
                            scientificName: '',
                          },
                        ],
                        quantity: 0,
                        supplierBusiness: {
                          id: '',
                        },
                        unit: 'KGM',
                      };
                    })
                  );
                  setIsModalOpen(false);
                }}
              >
                {t('common.save')}
              </Button>
            </Flex>
          </Checkbox.Group>
        )}
      />
    </Stack>
  );
};
