import * as yup from 'yup';
import { countryList } from '../../assets/countryList';
import { User } from '../shared/types';
import { useUser } from './useUser';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export type UserForm = User & {
  controlInput: {
    diligence: boolean;
    identifier1: string;
    identifier2: string;
  };
};

export const useUserForm = () => {
  const { data: user } = useUser();

  const updateUserSchema = yup.object({
    firstName: yup.string().required('Please enter first name'),
    lastName: yup.string().required('Please enter last name'),
    business: yup.object({
      name: yup.string().required('Please enter business name'),
      addressLine1: yup.string().required('Please enter address'),
      addressLine2: yup.string(),
      addressCity: yup.string().required('Please enter city'),
      addressPostcode: yup.string().required('Please enter postcode'),
      addressCountry: yup
        .string()
        .oneOf(countryList.map((country) => country.label))
        .required('Please select country'),
      vat: yup
        .string()
        .nullable()
        .when(' ', {
          is: () => !!user?.business?.vat,
          then: yup
            .string()
            .required(
              'Cannot remove existing VAT number. Please enter VAT number'
            ),
        }),
      eori: yup
        .string()
        .nullable()
        .when(' ', {
          is: () => !!user?.business?.eori,
          then: yup
            .string()
            .required(
              'Cannot remove existing EORI number. Please enter EORI number'
            ),
        }),
    }),
    controlInput: yup.object({
      diligence: yup.boolean(),
      diligenceValue1: yup.string().nullable(),
      diligenceValue2: yup.string().nullable(),
      identifier1: yup
        .string()
        .nullable()
        .when('diligenceValue1', {
          is: Boolean,
          then: yup.string().required('Please select identifier'),
        }),
      identifier2: yup
        .string()
        .nullable()
        .when('diligenceValue2', {
          is: Boolean,
          then: yup.string().required('Please select identifier'),
        }),
    }),
  });

  const defaultValues = {
    firstName: user?.firstName ?? '',
    lastName: user?.lastName ?? '',
    business: {
      name: user?.business?.newlyCreated ? '' : user?.business?.name,
      addressLine1: user?.business?.addressLine1 ?? '',
      addressLine2: user?.business?.addressLine2 ?? '',
      addressCity: user?.business?.addressCity ?? '',
      addressPostcode: user?.business?.addressPostcode ?? '',
      addressCountry: user?.business?.addressCountry ?? '',
      vat: user?.business?.vat ?? '',
      eori: user?.business?.eori ?? '',
    },
    controlInput: {
      diligence: Boolean(user?.business?.vat || user?.business?.eori),
      identifier1: user?.business?.eori ? 'business.eori' : '',
      identifier2: user?.business?.vat ? 'business.vat' : '',
      diligenceValue1: '',
      diligenceValue2: '',
    },
  };

  const form = useForm<UserForm>({
    defaultValues,
    resolver: yupResolver(updateUserSchema),
  });

  return form;
};
