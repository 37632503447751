import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Beta,
  Button,
  GenericErrorMessage,
  Modal,
  Radio,
  Select,
  Stack,
  TextInput,
} from '@liveeo/component-library';
import { useViewportSize } from '@mantine/hooks';
import { Controller, Control, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { countryList } from '../../../../assets/countryList';
import { useUser } from '../../../hooks';
import { HsCodeSelect } from './HsCodeSelect';
import { useTransactions } from '../../../hooks/useTransactions';
import {
  ActivityType,
  TransactionFormData,
  TransactionStatus,
} from '../../../shared/types/Transaction';
import { TransactionFormButton } from './TransactionFormButton';
import { Components } from './Components';
import { useQueryParam } from 'use-query-params';

type TransactionFormProps = {
  cancel: () => void;
};

const TransactionSchema = yup.object().shape({
  activityType: yup.string().required(),
  customId: yup.string().required(),
});

const CountrySelect = ({
  control,
}: {
  control: Control<TransactionFormData>;
}) => {
  const { t } = useTranslation();

  return (
    <Controller
      control={control}
      name="countryOfActivity"
      render={({ field }) => (
        <Select
          {...field}
          clearable
          data={countryList.map(({ label, value }) => ({
            value: value,
            label: t<string>(label),
          }))}
          label={t('transactions.form.countryOfActivity')}
          withAsterisk
          searchable
        />
      )}
    />
  );
};

export const TransactionForm = ({ cancel }: TransactionFormProps) => {
  const { transactionId } = useParams();
  const [search] = useQueryParam<string>('search');
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(!!search);
  const { width } = useViewportSize();
  const { data } = useUser();
  const { createTransaction, hsCodes, useTransactionById } = useTransactions();
  const { data: transaction } = useTransactionById(transactionId);

  const businessAddressCountryCode = data?.business?.addressCountryCode;

  const [transactionPayload, setTransactionPayload] =
    useState<TransactionFormData>({
      activityType: '' as ActivityType,
      components: [],
      customId: '',
      countryOfActivity: '',
      selectedHsCodes: [],
      status: TransactionStatus.DRAFT,
    });

  const { control, handleSubmit, reset, register, setValue, watch } =
    useForm<TransactionFormData>({
      defaultValues: transactionPayload,
      resolver: yupResolver(TransactionSchema),
    });

  const navigate = useNavigate();
  const transactionUrl = '/map/transactions?pan=1';
  const onSubmit: SubmitHandler<TransactionFormData> = (
    data: TransactionFormData
  ) => {
    const { selectedHsCodes, ...transactionData } = data;
    createTransaction.mutate(transactionData, {
      onSuccess: () => {
        reset();
        navigate(transactionUrl);
      },
    });
    reset();
    navigate(transactionUrl);
  };
  const selectedActivity = watch('activityType');

  useEffect(() => {
    if (transaction) {
      setTransactionPayload({
        activityType: transaction?.activityType,
        components: transaction?.components,
        customId: transaction?.customId,
        countryOfActivity: businessAddressCountryCode || '',
        selectedHsCodes: transaction?.components?.map((c) => c.hsn) || [],
        status: transaction?.status || TransactionStatus.DRAFT,
      });
      setValue('activityType', transaction?.activityType);
      setValue('components', transaction?.components);
      setValue('customId', transaction?.customId);
      setValue(
        'countryOfActivity',
        transaction.countryOfActivity || businessAddressCountryCode || ''
      );
      setValue(
        'selectedHsCodes',
        transaction?.components?.map((c) => c.hsn) || []
      );
      setValue('status', transaction?.status || TransactionStatus.DRAFT);
    }
  }, [transaction, businessAddressCountryCode, setValue]);
  return (
    <GenericErrorMessage>
      <Beta.Text variant="h1">{t('transactions.form.title')}</Beta.Text>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(onSubmit)(e);
        }}
      >
        <Beta.Text>{t('transactions.form.information')}</Beta.Text>
        <TextInput
          {...register('customId')}
          label={t('transactions.form.internalReference')}
        />
        <Controller
          name="activityType"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <Radio.Group
              mt="lg"
              label={t('transactions.form.activity')}
              {...field}
            >
              <Stack>
                <Radio
                  value="IMPORT"
                  label={t('transactions.activity.IMPORT')}
                />
                {selectedActivity === 'IMPORT' && (
                  <CountrySelect control={control} />
                )}
                <Radio
                  value="EXPORT"
                  label={t('transactions.activity.EXPORT')}
                />
                {selectedActivity === 'EXPORT' && (
                  <CountrySelect control={control} />
                )}
                <Radio
                  value="DOMESTIC"
                  label={t('transactions.activity.DOMESTIC')}
                />
                {selectedActivity === 'DOMESTIC' && (
                  <CountrySelect control={control} />
                )}
              </Stack>
            </Radio.Group>
          )}
        />
        <Components control={control} setValue={setValue} />
        <Stack mt="lg">
          <Button
            variant="outline"
            size="md"
            onClick={() => setIsModalOpen(true)}
            w="50%"
          >
            <Beta.Icon icon="plus" />
            {t('Add product or commodity')}
          </Button>
        </Stack>
        <TransactionFormButton
          setValue={setValue}
          transactionUrl={transactionUrl}
        />
      </form>
      {isModalOpen && (
        <Modal
          centered
          onClose={() => setIsModalOpen(false)}
          opened={isModalOpen}
          radius="md"
          size={width - 50}
          title={t('HS codes')}
        >
          <HsCodeSelect
            control={control}
            hsCodes={hsCodes || []}
            setIsModalOpen={setIsModalOpen}
            setValue={setValue}
            name="selectedHsCodes"
          />
        </Modal>
      )}
    </GenericErrorMessage>
  );
};
