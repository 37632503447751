import { useNavigate } from 'react-router-dom';
import { Button, Flex } from '@mantine/core';
import {
  TransactionFormData,
  TransactionStatus,
} from '../../../shared/types/Transaction';
import { UseFormSetValue } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const TransactionFormButton = ({
  setValue,
  transactionUrl,
}: {
  setValue: UseFormSetValue<TransactionFormData>;
  transactionUrl: string;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Flex justify="end" gap="sm" mt="lg">
      <Button
        variant="outline"
        size="sm"
        onClick={() => navigate(transactionUrl)}
      >
        {t('common.close')}
      </Button>
      <Button
        variant="outline"
        size="sm"
        onClick={() => {
          setValue('status', TransactionStatus.DRAFT);
        }}
        type="submit"
      >
        {t('common.saveAndClose')}
      </Button>
      <Button variant="outline" size="sm" type="submit">
        {t('common.submit')}
      </Button>
    </Flex>
  );
};
